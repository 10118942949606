import { Controller } from "@hotwired/stimulus";

import { Amplify } from "aws-amplify";
import { Hub } from 'aws-amplify/utils'
import { generateClient, CONNECTION_STATE_CHANGE, ConnectionState } from 'aws-amplify/api';
import { createChannelItem, deleteChannelItem } from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";
import * as queries from "../graphql/queries";

import { Cloudinary } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";

const cld = new Cloudinary({
  cloud: {
    cloudName: "forty-five-robots",
  },
});

export default class extends Controller {
  static values = {
    organizationId: String,
    channelIds: Array,
    currentChannelId: String,
    appSyncUrl: String,
    authToken: String,
    author: Object,
    firstIds: Object,
    fetching: Boolean,
    nextToken: String,
    scrolledToEnd: Boolean,
    assetsBucket: String,
    cdnEndpoint: String,
    signedUrl: String,
    connectionState: String
  };

  static targets = [
    "emptyState",
    "currentChannel",
    "chatField",
    "chats",
    "chatItem",
    "channelSelector",
    "unreadCounter",
    "spinner",
    "channelCompose",
    "disconnected",
    "connected",
  ];

  async connect() {
    this.configureAmplify();
    this.subscriptions = {};
    this.channelIdsValue.forEach((cid) => this.setupSubscription(cid));
    this.setupSubscriptionMonitoring()
    if (this.currentChannelIdValue) {
      this.currentChannelTarget.classList.remove("no-show");
      this.channelComposeTarget.classList.remove("no-show");
      this.displayUnreadCount();
      this.scrollToBottom();
    }
  }

  async disconnect() {
    await this.unsubscribeAll();
  }

  async unsubscribeAll() {
    for (const [channelId, subscription] of Object.entries(this.subscriptions)) {
      subscription.unsubscribe();
      delete this.subscriptions[channelId];
    }
  }

  setupSubscriptionMonitoring() {
    Hub.listen("api", (data) => {
      const { payload } = data;
      if (
        payload.event === CONNECTION_STATE_CHANGE
      ) {
        this.connectionStateValue = payload.data.connectionState.toString();
      }
    });
  }

  async connectionStateValueChanged(newValue, previousValue) {
    const connecting = ConnectionState.Connecting.toString()
    const connected = ConnectionState.Connected.toString()
    if (previousValue === connecting && newValue === connected) {
      // We just came back online, lets fetch from the top
      await this.fetchChannelItems(true);
    }
  }

  dispatchLocalNotification(channelItem) {
    const windowIsVisible = document.visibilityState == 'visible'
    const activeTab = this.tab.classList.contains('active')
    const isCurrentChannel = channelItem.channelId === this.currentChannelIdValue
    if ( windowIsVisible && activeTab && isCurrentChannel) {
      return
    }
    const text = channelItem.chatMessage?.text || channelItem.chatNotification?.text || "New Message"
    this.dispatch('notify', { detail: `${channelItem.author.name}: ${text}` })
  }

  configureAmplify() {
    const appSyncUrl = this.appSyncUrlValue;
    const region = process.env.AWS_REGION;

    Amplify.configure({
      API: {
        GraphQL: {
          endpoint: appSyncUrl,
          region: region,
          defaultAuthMode: 'lambda'
        }
      }
    })
    this.client = generateClient();
  }

  async selectChannel(event) {
    const channelId = event.target.dataset.channelSelectorChannelIdValue;

    this.scrolledToEndValue = false;
    this.currentChannelIdValue = channelId;
    this.emptyStateTarget.classList.add("no-show");
    this.currentChannelTarget.classList.remove("no-show");
    this.channelSelectorTarget.classList.remove("no-show");
    this.channelComposeTarget.classList.remove("no-show");
    this.tab.classList.remove("unread");
    this.chatsTarget.innerHTML = "";
    this.nextTokenValue = "";
    await this.fetchChannelItems();
    this.scrollToBottom();
  }

  scrollToBottom() {
    if (this.displayUnreadCount()) {
      return;
    }
    
    const currentChannel = this.currentChannelTarget;
    currentChannel.scrollTop = currentChannel.scrollHeight;
  }

  async scrolling(event) {
    const value = event.target.scrollTop;
    const element = event.target;

    // We need to make sure scrollTop is not zero becasue when we change channels the
    // scroll to end was getting set to true and nuking the first item to scroll to.
    this.scrolledToEndValue =
      element.scrollTop != 0 &&
      element.scrollHeight - Math.abs(element.scrollTop) <=
        element.clientHeight;
    if (value < 100 && this.nextTokenValue.length && !this.fetchingValue) {
      const firstItem = this.element.querySelector(".chat-wrapper.chat-item");
      await this.fetchChannelItems();
      firstItem.scrollIntoView(false);
    }
  }

  displayUnreadCount(scrollToFirstItem = true) {
    const firstItemId = this.firstIdsValue[this.currentChannelIdValue];
    const currentChannel = this.currentChannelTarget;
    if (currentChannel.clientHeight === currentChannel.scrollHeight) {
      this.updateFirstId(this.currentChannelIdValue, null);
      return false;
    }

    if (firstItemId && !this.scrolledToEndValue) {
      const firstItemDomId = this.itemDomId(firstItemId);
      if (scrollToFirstItem) {
        const element = document.getElementById(firstItemDomId);
        if (element) {
          element.scrollIntoView(false);
        }
      }
      // calculate items
      const nodelist = this.element.querySelectorAll(".chat-wrapper.chat-item");
      const elements = Array.from(nodelist);
      const index = elements.findIndex((e) => e.id === firstItemDomId);
      const unreadCount = elements.slice(index).length - 1;
      if (unreadCount > 0) {
        this.unreadCounterTarget.textContent = `${unreadCount} New Messages`;
        this.unreadCounterTarget.classList.remove("no-show");
      }
      return true;
    } else {
      return false;
    }
  }

  dismissUnreadCounterAndScroll() {
    this.dismissUnreadCounter();
    this.scrollToBottom();
  }

  dismissUnreadCounter() {
    this.unreadCounterTarget.classList.add("no-show");
    this.unreadCounterTarget.textContent = "";
    this.updateFirstId(this.currentChannelIdValue, null);
  }

  appendChannelItems(items, location = "afterbegin") {
    const orderedItems = location === "beforeend" ? [...items].reverse() : items;
    const replacementItems = {};
    orderedItems.forEach((item) => {
      const html = this.chatItemTemplate(item);
      const domId = this.itemDomId(item.id);
      const dateContainer = this.dateContainer(item, location);
      if (document.getElementById(domId)) {
        // Let's remove any repcement items from the collection and put them in memory
        replacementItems[item.id] = item;
      } else {
        // Then insert any new items
        dateContainer.insertAdjacentHTML(location, html);
      }
    });

    // Then replace any existing items with the replacements
    for (const [id, item] of Object.entries(replacementItems)) {
      const existingItem = document.getElementById(this.itemDomId(id));
      if (existingItem) {
        const html = this.chatItemTemplate(item);
        existingItem.outerHTML = html;
      }
    }
  }

  dateContainer(item, location) {
    const date = new Date(Date.parse(item.createdAt));
    const idStr = date.toLocaleDateString("en-US");
    const id = `dateContainer-${idStr}`;
    let container = document.getElementById(id);
    if (container) {
      return container;
    }
    const containerHTML = `<div class="date-heading"><span>${date.toLocaleDateString(
      "en-US",
      { weekday: "long", year: "numeric", month: "long", day: "numeric" }
    )}</div><div id="${id}" class="cf"></span></div>`;
    this.chatsTarget.insertAdjacentHTML(location, containerHTML);
    return document.getElementById(id);
  }

  updateFirstId(channelId, value) {
    const newValue = { ...this.firstIdsValue };
    newValue[channelId] = value;
    this.firstIdsValue = newValue;
  }

  setFirstId(channelId, value) {
    if (this.firstIdsValue[channelId] == null) {
      this.updateFirstId(channelId, value);
    }
  }

  receiveChannelItem(event) {
    // debugger
    const channelItem = event.detail
    const channelId = channelItem.channelId
    const id = channelItem.id
    const scrolledToEnd = this.scrolledToEndValue
    const isNewChannelItem = channelItem.createdAt === channelItem.updatedAt
    // IF current channel
    // append item
    if (channelItem.channelId === this.currentChannelIdValue) {
      this.appendChannelItems([channelItem], "beforeend");
      // IF scrolled to end
      // scroll to end
      if (scrolledToEnd) {
        this.scrollToBottom();
      } else if (isNewChannelItem) {
        // IF NOT scrolled to end and we have a new item
        // track first item if it's null
        // display unread count
        this.setFirstId(channelId, id);
        this.displayUnreadCount(false);
      }
    } else {
      // IF NOT the current channel
      // track first item if it's null
      this.setFirstId(channelId, id);
      this.dispatchLocalNotification(channelItem);
      this.showTabUnread();
    }
  }

  imageLoaded(event) {
    if (this.scrolledToEndValue) {
      this.scrollToBottom();
    }
  }

  itemDomId(id) {
    return `ChatItem${id}`;
  }

  openWindow(event) {
    if (this.hasOrganizationIdValue) {
      const url = `/organizations/${this.organizationIdValue}/channels/${this.currentChannelIdValue}`;
      window.open(url);
    }
  }

  fetchingValueChanged(fetching) {
    if (this.hasSpinnerTarget) {
      fetching
        ? this.spinnerTarget.classList.remove("no-show")
        : this.spinnerTarget.classList.add("no-show");
    }
  }

  scrolledToEndValueChanged(atEnd) {
    if (atEnd) {
      this.dismissUnreadCounter();
    }
  }

  showTabUnread() {
    this.tab.classList.add("unread");
  }

  get tab() {
    return document.querySelector(`.tabs-dashboard [data-tab="channels"]`);
  }

  chatItemTemplate(item) {
    const domId = this.itemDomId(item.id);
    const classes = ["chat-item", "cf", "chat-message"];
    const authorAvatarHTML = this.chatAuthorAvatarTemplate(item.author);
    const authorNameHTML = this.chatAuthorNameTemplate(item.author);
    const createdAtHTML = this.createdAtTemplate(item.createdAt);
    const isMine =
      item.author.id === this.authorValue.id ||
      item.author.id === `Member#${this.authorValue.id}`;
    const isDeleted = typeof item.deletedAt == "string";
    let bubbleHTML = "";
    let itemHTML;

    switch (true) {
      case item.chatMessage != null:
        itemHTML = this.chatMessageTemplate(item.chatMessage);
        break;
      case item.chatAttachment2 != null:
        itemHTML = this.chatAttachment2Template(item.chatAttachment2);
        classes.push("attachment");
        classes.push("attachment2");
        break;
      case item.chatAttachment != null:
        itemHTML = this.chatAttachmentTemplate(item.chatAttachment);
        classes.push("attachment");
        break;
      case item.chatNotification != null:
        itemHTML = this.chatNotificationTemplate(item.chatNotification);
        classes.push("notification");
        break;
      case item.chatImage2 != null:
        itemHTML = this.chatImage2Template(item.chatImage2);
        classes.push("image");
        break;
      case item.chatImage != null:
        itemHTML = this.chatImageTemplate(item.chatImage);
        classes.push("image");
        break;
      case item.chatVideo != null:
        itemHTML = this.chatVideoTemplate(item.chatVideo);
        classes.push("video");
        break;
      case item.deletedAt != null:
        itemHTML = `<div class="item-removed">The author has deleted this message.</div>`;
        classes.push("deleted");
        break;
      default:
        itemHTML = `<div class="item-unknown">This is unknown content.</div>`;
        classes.push("unknown");
    }
    if (isMine) {
      classes.push("mine");
    }
    if (isMine && !isDeleted) {
      bubbleHTML = `<div class="chat-edit cr-popup" data-controller="popup" data-action="click->popup#toggle click@window->popup#close">
        <div class="popuptext" data-popup-target="actions">
          <span data-action="click->channel-item#deleteItem">DELETE</span>
        </div>
      </div>`;
    }

    return `
    <div
      data-controller="channel-item"
      data-channel-item-author-id-value="${item.author.id}"
      data-channel-item-chat-item-id-value="${item.id}"
      data-channel-item-created-at-value="${item.createdAt}"
      data-channels-target="chatItem"
      id="${domId}"
      class="chat-wrapper ${classes.join(" ")}"
      >
      
      <div class="chat-author-avatar">
        ${authorAvatarHTML}
      </div>
      <div class="chat-message ${classes.join(" ")}">
        ${authorNameHTML}
        ${bubbleHTML}
        ${createdAtHTML}
        <div class="content">${itemHTML}</div>
      </div>
      </div>`;
  }

  createdAtTemplate(createdAt) {
    const time = new Date( Date.parse(createdAt) )
    return `<div class="created-at" data-controller="time-display" data-time-display-iso8601-value="${time.toISOString()}"></div>`
  }

  chatMessageTemplate(chatMessage) {
    return `${this.linkify(chatMessage.text)}`;
  }

  chatNotificationTemplate(chatNotification) {
    return `${this.linkify(chatNotification.text)}`;
  }

  imageServiceUrl(key, edits = {}) {
    const endpoint = this.cdnEndpointValue
    const bucket = this.assetsBucketValue
    const stringifiedObject = JSON.stringify({bucket, key, edits});
    const encodedObject = btoa(stringifiedObject);
    const url = `${endpoint}/${encodedObject}`;

    return url
  }

  chatAttachment2Template(chatAttachment) {
    const url = this.imageServiceUrl(chatAttachment.key)
    return `<a 
    data-controller="channel-attachment"
    data-channel-attachment-key-value="${chatAttachment.key}"
    data-channel-attachment-content-type-value="${chatAttachment.contentType}"
    data-channel-attachment-signed-url-value="${this.signedUrlValue}"
    data-channel-attachment-auth-token-value="${this.authTokenValue}"
    data-action="channel-attachment#fetchItem"
    href="#" target="_blank">${chatAttachment.fileName.substring(0, 30)}</a>`;
  }

  chatAttachmentTemplate(chatAttachment) {
    return `<a href="${
      chatAttachment.url
    }" target="_blank">${chatAttachment.fileName.substring(0, 30)}.${
      chatAttachment.extension
    }</a>`;
  }

  chatImageTemplate(chatImage) {
    const previewImage = cld.image(chatImage.publicId);
    previewImage.resize(fill().width(600)).format("auto");
    return `<a href="${chatImage.url}" target="_blank">
      <img src="${previewImage.toURL()}" class="preview" data-action="load->channels#imageLoaded">
    </a>`;
  }

  chatImage2Template(chatImage2) {
    const key = chatImage2.key
    const width = 600
    const height = width * chatImage2.heightPixels / chatImage2.widthPixels
    const previewEdits = {
      resize: {
        width: width,
        height: height,
        fit: "fill"
      }
    }

    const edits = {
      resize: {
        width: chatImage2.widthPixels,
        height: chatImage2.heightPixels,
        fit: "fill"
      }
    }
    
    const previewUrl = this.imageServiceUrl(key, previewEdits);
    const url = this.imageServiceUrl(key, edits)
    
    return `<a href="${url}" target="_blank">
      <img src="${previewUrl}" class="preview" data-action="load->channels#imageLoaded">
    </a>`;
  }

  chatVideoTemplate(chatVideo) {
    const previewImage = cld.video(chatVideo.publicId);
    previewImage.resize(fill().width(600)).format("jpg");
    return `<a href="${chatVideo.url}" target="_blank">
      <span>
        <img src="${previewImage.toURL()}" class="preview" data-action="load->channels#imageLoaded">
      </span>
    </a>`;
  }

  chatAuthorAvatarTemplate(author) {
    const avatarUrl = `https://res.cloudinary.com/forty-five-robots/image/upload/c_fill,g_face,h_100,w_100,r_max/d_CR-icon-navy_sxuan5.png/${author.avatar}.jpg`;
    return `<img src="${avatarUrl}" class="avatar">`;
  }

  chatAuthorNameTemplate(author) {
    return `<div class="author">${author.name}</div>`;
  }

  linkify(text) {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });
  }

  async fetchChannelItems(fromTheTop = false) {
    if (this.fetchingValue) {
      return;
    }
    if (this.currentChannelIdValue === "") {
      return;
    }
    this.fetchingValue = true;
    try {
      const authToken = this.authTokenValue;
      const variables = {
        channelId: this.currentChannelIdValue,
        sortDirection: "DESC",
        limit: 100,
      };
      const nextToken = this.nextTokenValue;
      if (nextToken.length && !fromTheTop) {
        variables.nextToken = nextToken;
      }
      const res = await this.client.graphql({
        query: queries.listChannelItems,
        variables,
        authToken
      });
      const location = fromTheTop ? "beforeend" : "afterbegin"
      this.appendChannelItems(res.data.listChannelItems.items, location);
      if (res.data.listChannelItems.nextToken) {
        this.nextTokenValue = res.data.listChannelItems.nextToken;
      } else {
        this.nextTokenValue = "";
      }
      // need to paginate recursivley with next token??
    } catch (error) {
      
    }
    this.fetchingValue = false;
  }

  async postMessageReturn(event) {
    const key = event.keyCode || event.which;
    if (key === 13) {
      await this.postMessage(event);
    }
  }

  async postMessage(event) {
    event.stopPropagation();
    const chatText = this.chatFieldTarget.value.trim();
    this.chatFieldTarget.value = "";
    if (chatText.length === 0) {
      return;
    }
    const newMessage = {
      chatMessage: {
        text: chatText,
      },
    };
    await this.postItem(newMessage);
  }

   getImageDimensions(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
  
      img.onload = function() {
        resolve({ widthPixels: this.width, heightPixels: this.height });
      };
  
      img.onerror = function() {
        reject('There was an error processing the image.');
      };
  
      img.src = URL.createObjectURL(file);
    });
  }

  async postUpload(event) {
    const detail = event.detail;
    const chatItem = {};

    // debugger

    const file = detail.file;
    const contentType = detail.file.type;
    const fileName = detail.file.name;
    const key = detail.key;
    const extension = fileName.split(".").pop();

    const basics = {
      key,
      contentType,
      fileName,
      extension
    }

    switch (contentType) {
      case contentType.match(/^image/)?.input:
        
        const dimensions = await this.getImageDimensions(file);
        chatItem.chatImage2 = { ...basics, ...dimensions };
        break;
      default:
        
        chatItem.chatAttachment2 = basics;
    }
    await this.postItem(chatItem);
  }

  async postItem(item) {
    const author = this.authorValue;
    const defaultItem = {
      memberId: author.id,
      channelId: this.currentChannelIdValue,
      author,
    };
    const newMessage = { ...defaultItem, ...item };
    try {
      const authToken = this.authTokenValue;
      await this.client.graphql({
        query: createChannelItem,
        variables: { input: newMessage },
        authToken: authToken
      });
    } catch (error) {
      
      
    }
  }

  async deleteItem(event) {
    const { id, createdAt, authorId } = event.detail;
    const deletedAt = new Date().toJSON();
    await this.postItem({ id, createdAt, deletedAt });
    try {
      const authToken = this.authTokenValue;
      await this.client.graphql({
        query: deleteChannelItem,
        variables: {
          input: {
            channelId: this.currentChannelIdValue,
            memberId: authorId,
            createdAt,
          },
        },
        authToken: authToken
      });
    } catch (error) {
      console.error(error);
    }
  }

  reloadPage() {
    location.reload();
  }

  async setupSubscription(channelId) {
    try {
      const authToken = this.authTokenValue;
      
      // New subscription syntax
      const subscription = this.client.graphql({ 
        query: subscriptions.onCreateChannelItem,
        variables: { channelId: channelId },
        authMode: "lambda",
        authToken: authToken
      }).subscribe({
        next: (res) => {
          const item = res.data.onCreateChannelItem;
          this.dispatch("newItem", { detail: item });
        },
        error: (error) => {
          console.error(error)
        }
      });
  
      this.subscriptions[channelId] = subscription;
    } catch (error) {
      console.error(error);
    }
  }

  getHeightAndWidthFromDataUrl = (dataURL) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });
}
